import React from 'react';
import { graphql, Link } from 'gatsby';
import Seo from "../components/seo"

const TrainingTemplate = ({ data, pageContext, location }) => {
  const { prefix, suffixes, color } = pageContext;

  const relationDatas = suffixes.map(({key, text }) => {
    const {nodes} = data[prefix.key + key];

    if (nodes.length > 0) {
      return { title: text, content: nodes[0].content }
    }

    return null;
  });
  return (
    <>
      <Seo title={prefix.text} />
      <div id="pageTitle">
        <h1>保安講習会
          <div className="pageTitle_sub">{prefix.text}</div>
        </h1>
      </div>
      <div className="breadClumb">
        <ul>

          <div className="breadClumb">
            <ul>
              <li>
                <Link to={"/"}>
                  HOME
                </Link>
              </li>
              <li>
                <Link to={"/training"}>
                  保安講習会
                </Link>
              </li>
              <li>{prefix.text}</li>
            </ul>
          </div>
        </ul>
      </div>

      <div id="main">
        <div className="contents">
          {
            relationDatas.filter(Boolean).map(({title, content }) => (
              <section className="contents_block">
                <h2 className={`header01 ${color}`}>{title}</h2>
                <div className="inner">
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </section>
            ))
          }
        </div>
      </div>
    </>
  );
};
export const query = graphql`
  query {
    allWpJrNorthNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrNorthApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrNorthForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrNorthLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrNorthInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrNorthSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastTokyoNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastTokyoApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastTokyoForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastTokyoLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastTokyoInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastTokyoSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastYokohamaNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastYokohamaApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastYokohamaForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastYokohamaLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastYokohamaInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastYokohamaSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastHachiojiNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastHachiojiApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastHachiojiForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastHachiojiLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastHachiojiInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastHachiojiSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastOmiyaNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastOmiyaApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastOmiyaForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastOmiyaLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastOmiyaInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrEastOmiyaSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }

    allWpJrTokaiNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrTokaiApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrTokaiForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrTokaiLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrTokaiInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrTokaiSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrWestNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrWestApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrWestForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrWestLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrWestInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrWestSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrKyusyuNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrKyusyuApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrKyusyuForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrKyusyuLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrKyusyuInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrKyusyuSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpSeibuNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpSeibuApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpSeibuForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpSeibuLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpSeibuInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpSeibuSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpOdakyuNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpOdakyuApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpOdakyuForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpOdakyuLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpOdakyuInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpOdakyuSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpTxNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpTxApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpTxForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpTxLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpTxInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpTxSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpTokyuNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpTokyuApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpTokyuForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpTokyuLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpTokyuInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpTokyuSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrShikokuNotice(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrShikokuApp(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrShikokuForm(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrShikokuLocation(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrShikokuInquiry(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }
    allWpJrShikokuSchedule(limit: 1, sort: {order: DESC, fields: date}) {
      nodes {
        content
      }
    }

  }
`;

export default TrainingTemplate;